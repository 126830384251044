import { Link } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LGEGraph from '../../assets/ENG/new-liquid-pool.svg'
import TokenCurve from '../../assets/ENG/new-token-curve.svg'
import TableRoi from '../../assets/ENG/table-roi.svg'
import {
  ChildSectionShortWidth,
  GlassCard,
  H2,
  ParentSection,
} from '../../components'
import Background from '../../images/blobs.svg'

export const Tokenomics: React.FC = () => {
  const { t } = useTranslation()

  return (
    <StyledParentSection>
      <StyledShortSection>
        <GlassCard>
          <FlexDiv>
            <TextGroup>
              <H2>{t('nftBacked')}</H2>

              <StyledP>
                {t('token1')}
                <br />
                <br />
                {t('token2')}
                <br />
                <br />
                {t('token3')}
              </StyledP>
            </TextGroup>
            <ImgWrapper>
              <StyledImg
                src={LGEGraph}
                alt="Circle graph of how Eth Pool is going to be divided"
              />
            </ImgWrapper>
          </FlexDiv>
        </GlassCard>

        <GlassCard>
          <FlexDiv>
            <TextGroup>
              <H2>{t('justice')}</H2>

              <StyledP>
                {t('initialPrice')}
                <br />
                <br />
                {t('noMaxLimit')}
                <br />
                <br />
                {t('maxPrice')}
              </StyledP>
            </TextGroup>
            <ImgWrapper>
              <StyledImg
                src={TokenCurve}
                alt="Graph of the fixed token price curve"
              />
            </ImgWrapper>
          </FlexDiv>
        </GlassCard>

        <GlassCard>
          <FlexDiv>
            <TextGroup>
              <H2>{t('winWin')}</H2>

              <StyledP>
                {t('nftRedemption')}
                <br />
                <br />
                {t('nftRedemptionScale')}
                <br />
                <br />
                {t('nftRedemptionWin')}
              </StyledP>
            </TextGroup>
            <ImgWrapper>
              <StyledImg src={TableRoi} alt="a table of possible roi" />
            </ImgWrapper>
          </FlexDiv>
        </GlassCard>
      </StyledShortSection>
    </StyledParentSection>
  )
}

export default Tokenomics

const HeroButton = styled(Link)`
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  border-radius: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 1.25rem;
  box-sizing: border-box;

  z-index: 1;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  border-radius: 3.5rem / 100%;

  background: rgb(0, 255, 194);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 194, 1) 0%,
    rgba(0, 234, 223, 1) 100%
  );

  &:hover {
    box-shadow: 0 0 5px #00ffc2, 0 0 15px #00ffc2, 0 0 30px #00ffc2,
      0 0 60px #00ffc2;
  }

  @media (min-width: 200px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  @media (min-width: 750px) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
`

const CenterButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledShortSection = styled(ChildSectionShortWidth)`
  display: grid;
  grid-template-rows: auto auto auto auto;
  row-gap: 5rem;

  @media (min-width: 200px) {
    width: 95%;
  }

  @media (min-width: 750px) {
    width: 85%;
  }
`

const StyledParentSection = styled(ParentSection)`
  background: url(${Background}) no-repeat;
  background-size: cover;
  padding-top: 5rem;
`

const StyledP = styled.p`
  color: #ffffff;
  line-height: 150%;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;

  @media (min-width: 200px) {
    margin-top: 1rem;
  }

  @media (min-width: 750px) {
    margin-top: 2rem;
  }
  @media (min-width: 1200px) {
    margin-top: 3rem;
  }
`

const TextGroup = styled.div`
  height: 100%;
  width: 100%;

  @media (min-width: 200px) {
    width: 100%;
  }

  @media (min-width: 750px) {
  }

  @media (min-width: 1200px) {
    width: 50%;
  }
`

const FlexDiv = styled.div`
  display: flex;

  @media (min-width: 200px) {
    flex-direction: column;
  }

  @media (min-width: 750px) {
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
  }
`

const ImgWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 200px) {
    width: 100%;
  }

  @media (min-width: 750px) {
  }
  @media (min-width: 1200px) {
    width: 50%;
    justify-content: flex-end;
  }
`

const StyledImg = styled.img`
  @media (min-width: 200px) {
    margin-top: 2rem;
    width: 100%;
  }

  @media (min-width: 750px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    margin-top: 0;
    width: 80%;
  }
`
